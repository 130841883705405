import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Permissions
import {
    canViewAnyUser, canViewUser, canCreateUser, canUpdateUser,
    canViewSignatureGroup, canCreateSignatureGroup,
    canViewWorkSituation, canCreateWorkSituation, canUpdateWorkSituation,
    canViewReflectionPhase, canCreateReflectionPhase, canUpdateReflectionPhase,
    canViewRoadmap, canCreateRoadmap, canUpdateRoadmap,
    canCreatePlannedStep, canUpdatePlannedStep,
    canCreateSimplifiedInternship, canUpdateSimplifiedInternship,
    canCreateScriptMatch, canUpdateScriptMatch,
} from 'permissions';
import { RequirePerm } from '../index';

// Views
import ActionWorkSituationFrom from 'views/authenticated/work-situations/action-work-situation-from';
import ActionReflectionPhaseFrom from 'views/authenticated/reflection-phases/action-reflection-phase-from';

/* * *
* Lazy Imports
*/

const Users = lazy(() => import('../../../views/authenticated/users'));
const User = lazy(() => import('../../../views/authenticated/users/user'));
const CreateUser = lazy(() => import('../../../views/authenticated/users/create-user'));
const UpdateUser = lazy(() => import('../../../views/authenticated/users/update-user'));

const UserDashboardFromUser = lazy(() => import('../../../views/authenticated/users/user/user-dashboard-from-user'));
const UserRoadmaps = lazy(() => import('../../../views/authenticated/users/user/user-roadmaps'));
const UserWorkSituations = lazy(() => import('../../../views/authenticated/users/user/user-work-situations'));
const UserSignatureGroups = lazy(() => import('../../../views/authenticated/users/user/user-signature-groups'));
const UserProofItems = lazy(() => import('../../../views/authenticated/users/user/user-proof-items'));
const UserSheets = lazy(() => import('../../../views/authenticated/users/user/user-sheets'));

const CreateWorkSituationFromUser = lazy(() => import('../../../views/authenticated/work-situations/create-work-situation-from-user'));
const CreateWorkSituationFromRoadmap = lazy(() => import('../../../views/authenticated/work-situations/create-work-situation-from-roadmap'));

// Portfolio
const UsedCustomRepository = lazy(() => import('../../../views/authenticated/portfolio/used-custom-repository'));
const UsedSkillRepository = lazy(() => import('../../../views/authenticated/portfolio/used-skill-repository'));
const UsedAtypicalRepository = lazy(() => import('../../../views/authenticated/portfolio/used-atypical-repository'));

// Signature Groups
const SignatureGroupFromUser = lazy(() => import('../../../views/authenticated/signature-groups/signature-group-from-user'));
const CreateSignatureGroupFromUser = lazy(() => import('../../../views/authenticated/signature-groups/create-signature-group-from-user'));

// Roadmap
const Roadmap = lazy(() => import('../../../views/authenticated/roadmaps/roadmap'));
const CreateRoadmapFromUser = lazy(() => import('../../../views/authenticated/roadmaps/create-roadmap-from-user'));
const UpdateRoadmap = lazy(() => import('../../../views/authenticated/roadmaps/update-roadmap'));
const PDFExport = lazy(() => import('../../../views/authenticated/roadmaps/roadmap/documents/pdf-export'));
const IndividualTrainingProtocol = lazy(() => import('../../../views/authenticated/roadmaps/roadmap/documents/individual-training-protocol'));

// Planned Steps
const CreatePlannedStep = lazy(() => import('../../../views/authenticated/roadmaps/roadmap/planned-steps/create-planned-step'));
const UpdatePlannedStep = lazy(() => import('../../../views/authenticated/roadmaps/roadmap/planned-steps/update-planned-step'));

// Self Placements
const CreateSelfPlacement = lazy(() => import('../../../views/authenticated/self-placements/create-self-placement'));
const UpdateSelfPlacement = lazy(() => import('../../../views/authenticated/self-placements/update-self-placement'));

// Self Evaluations
const CreateSelfEvaluation = lazy(() => import('../../../views/authenticated/self-evaluations/create-self-evaluation'));
const UpdateSelfEvaluation = lazy(() => import('../../../views/authenticated/self-evaluations/update-self-evaluation'));

// Evaluations
const CreateEvaluation = lazy(() => import('../../../views/authenticated/evaluations/create-evaluation'));
const UpdateEvaluation = lazy(() => import('../../../views/authenticated/evaluations/update-evaluation'));

// Supports
const CreateSupport = lazy(() => import('../../../views/authenticated/supports/create-support'));
const UpdateSupport = lazy(() => import('../../../views/authenticated/supports/update-support'));

// Other Trainings
const CreateOtherTraining = lazy(() => import('../../../views/authenticated/other-trainings/create-other-training'));
const UpdateOtherTraining = lazy(() => import('../../../views/authenticated/other-trainings/update-other-training'));

// Simplified Internships
const CreateSimplifiedInternship = lazy(() => import('../../../views/authenticated/internships/create-simplified-internship'));
const UpdateSimplifiedInternship = lazy(() => import('../../../views/authenticated/internships/update-simplified-internship'));

// Professional Practices
const CreateProfessionalPractice = lazy(() => import('../../../views/authenticated/professional-practices/create-professional-practice'));
const UpdateProfessionalPractice = lazy(() => import('../../../views/authenticated/professional-practices/update-professional-practice'));

// Reflexive Results
const CreateReflexiveResult = lazy(() => import('../../../views/authenticated/reflexive-results/create-reflexive-result'));
const UpdateReflexiveResult = lazy(() => import('../../../views/authenticated/reflexive-results/update-reflexive-result'));

// Script Matches
const CreateScriptMatch = lazy(() => import('../../../views/authenticated/script-matches/create-script-match'));
const UpdateScriptMatch = lazy(() => import('../../../views/authenticated/script-matches/update-script-match'));

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- USER ROUTES -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UserRoutes() {
    return (
        <Routes>
            <Route index element={<RequirePerm perm={canViewAnyUser()}><Users /></RequirePerm>} />
            <Route path="create" element={<RequirePerm perm={canCreateUser()}><CreateUser /></RequirePerm>} />
            <Route path=":id">
                <Route path="update" element={<RequirePerm perm={canUpdateUser()}><UpdateUser /></RequirePerm>} />
                <Route element={<RequirePerm perm={canViewUser()}><User /></RequirePerm>}>
                    <Route index element={<UserDashboardFromUser />} />
                    <Route path="portfolio/:repositoryId">
                        <Route path="used-custom-repository" element={<UsedCustomRepository />} />
                        <Route path="used-skill-repository" element={<UsedSkillRepository />} />
                        <Route path="used-atypical-repository" element={<UsedAtypicalRepository />} />
                    </Route>
                    <Route path="sheets" element={<UserSheets />} />
                    <Route path="proof-items" element={<UserProofItems />} />
                    <Route path="create-signature-group" element={<RequirePerm perm={canCreateSignatureGroup()}><CreateSignatureGroupFromUser /></RequirePerm>} />
                    <Route path="signature-groups" element={<UserSignatureGroups />} />
                    <Route path="signature-groups/:signatureGroupId" element={<RequirePerm perm={canViewSignatureGroup()}><SignatureGroupFromUser /></RequirePerm>} />
                    <Route path="create-work-situation" element={<RequirePerm perm={canCreateWorkSituation({})}><CreateWorkSituationFromUser /></RequirePerm>} />
                    <Route path="work-situations" element={<UserWorkSituations />} />
                    <Route path="work-situations/:workSituationId">
                        <Route index element={<RequirePerm perm={canViewWorkSituation()}><ActionWorkSituationFrom source="USER" action="VIEW" /></RequirePerm>} />
                        <Route path="update" element={<RequirePerm perm={canUpdateWorkSituation({})}><ActionWorkSituationFrom source="USER" action="UPDATE" /></RequirePerm>} />
                        <Route path="signatures" element={<ActionWorkSituationFrom source="USER" action="SIGN" />} />
                        <Route path="create-reflection-phase/:learnerWorkSituationId" element={<RequirePerm perm={canCreateReflectionPhase({})}><ActionReflectionPhaseFrom source="USER" action="CREATE" /></RequirePerm>} />
                        <Route path="reflection-phase/:reflectionPhaseId">
                            <Route index element={<RequirePerm perm={canViewReflectionPhase()}><ActionReflectionPhaseFrom source="USER" action="VIEW" /></RequirePerm>} />
                            <Route path="update" element={<RequirePerm perm={canUpdateReflectionPhase({})}><ActionReflectionPhaseFrom source="USER" action="UPDATE" /></RequirePerm>} />
                            <Route path="signatures" element={<ActionReflectionPhaseFrom source="USER" action="SIGN" />} />
                        </Route>
                    </Route>
                    <Route path="create-roadmap" element={<RequirePerm perm={canCreateRoadmap()}><CreateRoadmapFromUser /></RequirePerm>} />
                    <Route path="roadmaps" element={<UserRoadmaps />} />
                    <Route path="roadmaps/:roadmapId">
                        <Route index element={<RequirePerm perm={canViewRoadmap()}><Roadmap /></RequirePerm>} />
                        <Route path="update" element={<RequirePerm perm={canUpdateRoadmap()}><UpdateRoadmap /></RequirePerm>} />
                        <Route path="pdf-export" element={<PDFExport />} />
                        <Route path="individual-training-protocol" element={<IndividualTrainingProtocol />} />
                        <Route path="create-planned-step" element={<RequirePerm perm={canCreatePlannedStep()}><CreatePlannedStep /></RequirePerm>} />
                        <Route path="update-planned-step/:plannedStepId" element={<RequirePerm perm={canUpdatePlannedStep()}><UpdatePlannedStep /></RequirePerm>} />
                        <Route path="create-self-placement" element={<CreateSelfPlacement />} />
                        <Route path="update-self-placement/:selfPlacementId" element={<UpdateSelfPlacement />} />
                        <Route path="create-self-evaluation" element={<CreateSelfEvaluation />} />
                        <Route path="update-self-evaluation/:selfEvaluationId" element={<UpdateSelfEvaluation />} />
                        <Route path="create-work-situation" element={<CreateWorkSituationFromRoadmap />} />
                        <Route path="work-situations/:workSituationId">
                            <Route index element={<ActionWorkSituationFrom source="ROADMAP" action="VIEW" />} />
                            <Route path="update" element={<ActionWorkSituationFrom source="ROADMAP" action="UPDATE" />} />
                            <Route path="signatures" element={<ActionWorkSituationFrom source="ROADMAP" action="SIGN" />} />
                            <Route path="create-reflection-phase/:learnerWorkSituationId" element={<ActionReflectionPhaseFrom source="ROADMAP" action="CREATE" />} />
                            <Route path="reflection-phase/:reflectionPhaseId">
                                <Route index element={<ActionReflectionPhaseFrom source="ROADMAP" action="VIEW" />} />
                                <Route path="update" element={<ActionReflectionPhaseFrom source="ROADMAP" action="UPDATE" />} />
                                <Route path="signatures" element={<ActionReflectionPhaseFrom source="ROADMAP" action="SIGN" />} />
                            </Route>
                        </Route>
                        <Route path="create-evaluation" element={<CreateEvaluation />} />
                        <Route path="update-evaluation/:evaluationId" element={<UpdateEvaluation />} />
                        <Route path="create-support" element={<CreateSupport />} />
                        <Route path="update-support/:supportId" element={<UpdateSupport />} />
                        <Route path="create-professional-practice" element={<CreateProfessionalPractice />} />
                        <Route path="update-professional-practice/:professionalPracticeId" element={<UpdateProfessionalPractice />} />
                        <Route path="create-reflexive-result" element={<CreateReflexiveResult />} />
                        <Route path="update-reflexive-result/:reflexiveResultId" element={<UpdateReflexiveResult />} />
                        <Route path="create-other-training" element={<CreateOtherTraining />} />
                        <Route path="update-other-training/:otherTrainingId" element={<UpdateOtherTraining />} />
                        <Route path="create-simplified-internship" element={<RequirePerm perm={canCreateSimplifiedInternship()}><CreateSimplifiedInternship /></RequirePerm>} />
                        <Route path="update-simplified-internship/:internshipId" element={<RequirePerm perm={canUpdateSimplifiedInternship()}><UpdateSimplifiedInternship /></RequirePerm>} />
                        <Route path="create-script-match" element={<RequirePerm perm={canCreateScriptMatch()}><CreateScriptMatch /></RequirePerm>} />
                        <Route path="update-script-match/:scriptMatchId" element={<RequirePerm perm={canUpdateScriptMatch()}><UpdateScriptMatch /></RequirePerm>} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}
