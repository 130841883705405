import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Permissions
import {
    canViewAnyActivitySector, canViewActivitySector, canCreateActivitySector, canUpdateActivitySector,
    canViewAnyCertifier, canViewCertifier, canCreateCertifier, canUpdateCertifier,
    canViewAnyReflexivityAxis, canViewReflexivityAxis, canCreateReflexivityAxis, canUpdateReflexivityAxis,
    canViewAnyScriptThumbnail,
    canCreateScriptThumbnail,
    canViewScriptThumbnail,
    canUpdateScriptThumbnail,
} from 'permissions';
import { DocumentTitleRoute, RequirePerm } from '../index';

/* * *
* Lazy Imports
*/

const Management = lazy(() => import('../../../views/authenticated/management'));

// Activity Sectors
const ActivitySectors = lazy(() => import('../../../views/authenticated/management/activity-sectors'));
const ActivitySector = lazy(() => import('../../../views/authenticated/management/activity-sectors/activity-sector'));
const CreateActivitySector = lazy(() => import('../../../views/authenticated/management/activity-sectors/create-activity-sector'));
const UpdateActivitySector = lazy(() => import('../../../views/authenticated/management/activity-sectors/update-activity-sector'));

// Certifiers
const Certifiers = lazy(() => import('../../../views/authenticated/management/certifiers'));
const Certifier = lazy(() => import('../../../views/authenticated/management/certifiers/certifier'));
const CreateCertifier = lazy(() => import('../../../views/authenticated/management/certifiers/create-certifier'));
const UpdateCertifier = lazy(() => import('../../../views/authenticated/management/certifiers/update-certifier'));

// Reflexivity Axes
const ReflexivityAxes = lazy(() => import('../../../views/authenticated/management/reflexivity-axes'));
const ReflexivityAxis = lazy(() => import('../../../views/authenticated/management/reflexivity-axes/reflexivity-axis'));
const CreateReflexivityAxis = lazy(() => import('../../../views/authenticated/management/reflexivity-axes/create-reflexivity-axis'));
const UpdateReflexivityAxis = lazy(() => import('../../../views/authenticated/management/reflexivity-axes/update-reflexivity-axis'));

// Script Matches
const ScriptThumbnails = lazy(() => import('../../../views/authenticated/management/script-thumbnails'));
const ScriptThumbnail = lazy(() => import('../../../views/authenticated/management/script-thumbnails/script-thumbnail'));
const CreateScriptThumbnail = lazy(() => import('../../../views/authenticated/management/script-thumbnails/create-script-thumbnail'));
const UpdateScriptThumbnail = lazy(() => import('../../../views/authenticated/management/script-thumbnails/update-script-thumbnail'));

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- MANAGEMENT ROUTES ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ManagementRoutes() {
    return (
        <Routes>
            <Route index element={<Management />} />
            <Route path="activity-sectors">
                <Route index element={<RequirePerm perm={canViewAnyActivitySector()}><ActivitySectors /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateActivitySector()}><CreateActivitySector /></RequirePerm>} />
                <Route path=":id" element={<DocumentTitleRoute title="activity_sector" id="id" />}>
                    <Route index element={<RequirePerm perm={canViewActivitySector()}><ActivitySector /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateActivitySector()}><UpdateActivitySector /></RequirePerm>} />
                </Route>
            </Route>
            <Route path="certifiers">
                <Route index element={<RequirePerm perm={canViewAnyCertifier()}><Certifiers /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateCertifier()}><CreateCertifier /></RequirePerm>} />
                <Route path=":certifierId" element={<DocumentTitleRoute title="certifier" id="certifierId" />}>
                    <Route index element={<RequirePerm perm={canViewCertifier()}><Certifier /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateCertifier()}><UpdateCertifier /></RequirePerm>} />
                </Route>
            </Route>
            <Route path="reflexivity-axes">
                <Route index element={<RequirePerm perm={canViewAnyReflexivityAxis()}><ReflexivityAxes /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateReflexivityAxis()}><CreateReflexivityAxis /></RequirePerm>} />
                <Route path=":reflexivityAxisId" element={<DocumentTitleRoute title="reflexivity_axis" id="reflexivityAxisId" />}>
                    <Route index element={<RequirePerm perm={canViewReflexivityAxis()}><ReflexivityAxis /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateReflexivityAxis()}><UpdateReflexivityAxis /></RequirePerm>} />
                </Route>
            </Route>
            <Route path="script-thumbnails">
                <Route index element={<RequirePerm perm={canViewAnyScriptThumbnail()}><ScriptThumbnails /></RequirePerm>} />
                <Route path="create" element={<RequirePerm perm={canCreateScriptThumbnail()}><CreateScriptThumbnail /></RequirePerm>} />
                <Route path=":scriptThumbnailId" element={<DocumentTitleRoute title="script_thumbnail" id="scriptThumbnailId" />}>
                    <Route index element={<RequirePerm perm={canViewScriptThumbnail()}><ScriptThumbnail /></RequirePerm>} />
                    <Route path="update" element={<RequirePerm perm={canUpdateScriptThumbnail()}><UpdateScriptThumbnail /></RequirePerm>} />
                </Route>
            </Route>
        </Routes>
    );
}
