import { useTranslation } from 'react-i18next';

// MUI
import { Fab } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';

// Services
import { IWorkSituation } from 'services/interfaces';

// Components
import Title from 'components/title';

// Icons
import WeatherIcon from 'icons/weather-icon';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- TRAINERS -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ITrainersProps {
    workSituation: IWorkSituation | undefined;
    onOpenSignature: (name: string, url: string) => void;
}

export default function Trainers(props: ITrainersProps) {

    const { t } = useTranslation();
    const { workSituation, onOpenSignature } = props;
    const signatureEnabled = ! (workSituation?.signOffSheetUrl || workSituation?.signatureDisabled);

    return (
        <div style={{ overflow: 'auto' }}>
            <table>
                <thead>
                    <tr>
                        <td
                            className="table-td"
                            style={{ minWidth: 200 }}
                        >
                            <Title>
                                {t('trainers')}
                            </Title>
                        </td>
                        <td
                            className="table-td text-center"
                            style={{ minWidth: 140 }}
                        >
                            <Title>
                                {t('weather')}
                            </Title>
                        </td>
                        {signatureEnabled && (
                            <td
                                className="table-td"
                                style={{ minWidth: 200 }}
                            >
                                <Title>
                                    {t('signature')}
                                </Title>
                            </td>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {(
                        workSituation?.trainerWorkSituations
                        && workSituation.trainerWorkSituations.length > 0
                    ) && (
                        workSituation.trainerWorkSituations.map(ws => (
                            <tr key={ws.id}>
                                <td className="table-td">
                                    {ws.trainer?.fullName}
                                </td>
                                <td className="table-td text-center">
                                    <WeatherIcon weather={ws.weather} />
                                </td>
                                {signatureEnabled && (
                                    <td className="table-td">
                                        {ws.signatureUrl ? (
                                            <>
                                                <Fab
                                                    onClick={() => onOpenSignature(ws.trainer?.fullName, ws.signatureUrl)}
                                                    color="secondary"
                                                >
                                                    <VisibilityIcon />
                                                </Fab>
                                                <span style={{ marginLeft: 8 }}>
                                                    {t('signed')}
                                                </span>
                                            </>
                                        ) : (
                                            t('waiting_signature')
                                        )}
                                    </td>
                                )}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
}
