import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Fab } from '@mui/material';
import { Visibility as VisibilityIcon, Add as AddIcon } from '@mui/icons-material';

// Permissions
import { canUpdateLearnerWorkSituation } from 'permissions';

// Services
import { IWorkSituation, ILearnerWorkSituation } from 'services/interfaces';
import { EWorkSituationType } from 'services/enums';
import { getBreadcrumbItems } from 'services/utils';

// Components
import Title from 'components/title';

// Icons
import WeatherIcon from 'icons/weather-icon';

// Views
import RoadmapDialog from './roadmap-dialog';
import UpdateLearnerWorkSituation from 'views/authenticated/work-situations/update-learner-work-situation';
import ReflectionPhasesDialog from './reflection-phase-dialog';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LEARNERS -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ILearnersProps {
    source: 'USER' | 'COMPANY' | 'ROADMAP';
    refetch: any;
    workSituation: IWorkSituation | undefined;
    onOpenSignature: (name: string, url: string) => void;
}

function getInitialLearnerWorkSituation(workSituation: IWorkSituation | undefined, locationState: any) {
    if (
        locationState?.learnerId
        && workSituation?.learnerWorkSituations
    ) {
        return workSituation.learnerWorkSituations.find(lws => lws.learner?.id === locationState?.learnerId) || null;
    }
    return null;
}

export default function Learners(props: ILearnersProps) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    const { state: locationState } = location;
    const { source, refetch, workSituation, onOpenSignature } = props;

    const signatureEnabled = ! (workSituation?.signOffSheetUrl || workSituation?.signatureDisabled);
    const resultEnabled = !! (workSituation?.learnerWorkSituations && workSituation.learnerWorkSituations.some(lws => lws.result !== ''));
    const personalResultEnabled = !! (workSituation?.learnerWorkSituations && workSituation.learnerWorkSituations.some(lws => lws.personalResult != null));

    const [lws, setLws] = useState<ILearnerWorkSituation | null>(getInitialLearnerWorkSituation(workSituation, locationState));
    const [openRoadmap, setOpenRoadmap] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(locationState?.action === 'linkRoadmapToWorkSituation');
    const [openReflectionPhases, setOpenReflectionPhases] = useState(false);

    const navigateToCreateRP = (learnerWorkSituation: ILearnerWorkSituation) => {

        let basePath = location.pathname;

        if (
            source !== 'COMPANY'
            && locationState?.learnerId !== learnerWorkSituation.learner?.id
        ) {
            const { workSituationLink } = getBreadcrumbItems({
                mainId: learnerWorkSituation.learner?.id,
                source: 'USER',
                items: [],
                workSituation: workSituation,
            });

            basePath = workSituationLink;
        }

        navigate(basePath + '/create-reflection-phase/' + learnerWorkSituation.id);
    };

    return (
        <>
            <div style={{ overflow: 'auto', marginTop: 24 }}>
                <table>
                    <thead>
                        <tr>
                            <td
                                className="table-td"
                                style={{ minWidth: 200 }}
                            >
                                <Title>
                                    {t('learners')}
                                </Title>
                            </td>
                            {resultEnabled && (
                                <td
                                    className="table-td"
                                    style={{ minWidth: 200 }}
                                >
                                    <Title>
                                        {t('result.2')}
                                    </Title>
                                </td>
                            )}
                            {personalResultEnabled && (
                                <td
                                    className="table-td"
                                    style={{ minWidth: 200 }}
                                >
                                    <Title>
                                        {t('personal_result')}
                                    </Title>
                                </td>
                            )}
                            <td
                                className="table-td text-center"
                                style={{ minWidth: 140 }}
                            >
                                <Title>
                                    {t('weather')}
                                </Title>
                            </td>
                            {signatureEnabled && (
                                <td
                                    className="table-td"
                                    style={{ minWidth: 200 }}
                                >
                                    <Title>
                                        {t('signature')}
                                    </Title>
                                </td>
                            )}
                            <td
                                className="table-td"
                                style={{ minWidth: 200 }}
                            >
                                <Title>
                                    {t('roadmap')}
                                </Title>
                            </td>
                            {workSituation?.workSituationType === EWorkSituationType.WORK_SITUATION && ( 
                                <td
                                    className="table-td"
                                    style={{ minWidth: 180 }}
                                >
                                    <Title>
                                        {t('reflection_phases')}
                                    </Title>
                                </td>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {(
                            workSituation?.learnerWorkSituations
                            && workSituation.learnerWorkSituations.length > 0
                        ) && (
                            workSituation.learnerWorkSituations?.map(lws => (
                                <tr key={lws.id}>
                                    <td className="table-td">
                                        {lws.learner?.fullName}
                                    </td>
                                    {resultEnabled && (
                                        <td className="table-td">
                                            {lws.result}
                                        </td>
                                    )}
                                    {personalResultEnabled && (
                                        <td className="table-td">
                                            {lws.personalResult}
                                        </td>
                                    )}
                                    <td className="table-td text-center">
                                        <WeatherIcon weather={lws.weather} />
                                    </td>
                                    {signatureEnabled && (
                                        <td className="table-td">
                                            {lws.signatureUrl ? (
                                                <>
                                                    <Fab
                                                        onClick={() => onOpenSignature(lws.learner?.fullName, lws.signatureUrl)}
                                                        color="secondary"
                                                    >
                                                        <VisibilityIcon />
                                                    </Fab>
                                                    <span style={{ marginLeft: 8 }}>
                                                        {t('signed')}
                                                    </span>
                                                </>
                                            ) : (
                                                t('waiting_signature')
                                            )}
                                        </td>
                                    )}
                                    <td className="table-td">
                                        {lws.roadmap ? (
                                            <>
                                                <Fab
                                                    onClick={() => { setLws(lws); setOpenRoadmap(true); }}
                                                    color="secondary"
                                                >
                                                    <VisibilityIcon />
                                                </Fab>
                                                <span style={{ marginLeft: 8 }}>
                                                    {t('view_roadmap')}
                                                </span>
                                            </>
                                        ) : (
                                            canUpdateLearnerWorkSituation(lws) && (
                                                <>
                                                    <Fab
                                                        onClick={() => { setLws(lws); setOpenUpdate(true); }}
                                                        color="secondary"
                                                    >
                                                        <AddIcon />
                                                    </Fab>
                                                    <span style={{ marginLeft: 8 }}>
                                                        {t('associate_roadmap')}
                                                    </span>
                                                </>
                                            )
                                        )}
                                    </td>
                                    {workSituation?.workSituationType === EWorkSituationType.WORK_SITUATION && ( 
                                        <td className="table-td">
                                            {(lws.reflectionPhases && lws.reflectionPhases.length > 0) ? (
                                                <>
                                                    <Fab
                                                        onClick={() => { setLws(lws); setOpenReflectionPhases(true); }}
                                                        color="secondary"
                                                        sx={{ marginRight: 1 }}
                                                    >
                                                        <VisibilityIcon />
                                                    </Fab>
                                                    <span>
                                                        {t('view')}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {canUpdateLearnerWorkSituation(lws) && (
                                                        <Fab
                                                            onClick={() => navigateToCreateRP(lws)}
                                                            color="secondary"
                                                            sx={{ marginRight: 1 }}
                                                        >
                                                            <AddIcon />
                                                        </Fab>
                                                    )}
                                                    <span>
                                                        {t('none.2')}
                                                    </span>
                                                </>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {/* -------------------- ROADMAP DIALOG -------------------- */}
            {(openRoadmap && lws) && (
                <RoadmapDialog
                    workSituation={workSituation}
                    onClose={() => setOpenRoadmap(false)}
                    onNavigateToRoadmap={() => navigate('/app/users/' + lws.learner?.id + '/roadmaps/' + lws.roadmap?.id)}
                    onOpenUpdateLearnerWorkSituation={() => { setOpenRoadmap(false); setOpenUpdate(true); }}
                />
            )}
            {/* -------------------- UPDATE LEARNER WORK SITUATION -------------------- */}
            {(openUpdate && lws) && (
                <UpdateLearnerWorkSituation
                    actionType="ROADMAP"
                    learnerWorkSituation={lws}
                    onClose={() => setOpenUpdate(false)}
                    refetch={() => { setOpenUpdate(false); refetch(); }}
                    customRepository={workSituation?.customRepository}
                />
            )}
            {/* -------------------- REFLECTION PHASES DIALOG -------------------- */}
            {(openReflectionPhases && lws) && (
                <ReflectionPhasesDialog
                    source={source}
                    workSituation={workSituation}
                    learnerWorkSituation={lws}
                    onClose={() => setOpenReflectionPhases(false)}
                />
            )}
        </>
    );
}
