import { SvgIconProps } from '@mui/material';
import {
    AccountTree, CheckBoxOutlined, ViewAgenda, Category, Business, AutoAwesomeMotion, Insights,
    FormatListBulleted, LocalActivity, GradeOutlined, Grade, PsychologyAlt, NewReleases, Star,
    Collections, VerifiedUser, Description, HistoryEdu, ReceiptLong, Style,
} from '@mui/icons-material';

// Services
import { yellowMain, magentaMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- COMPANY ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function CompanyIcon(props: SvgIconProps) {
    return <Business {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MODEL ICON -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ModelIcon(props: SvgIconProps) {
    return <AutoAwesomeMotion {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- ACTIVITY SECTOR ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ActivitySectorIcon(props: SvgIconProps) {
    return <Category {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ CERTIFIER ICON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function CertifierIcon(props: SvgIconProps) {
    return <LocalActivity {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ROADMAP ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function RoadmapIcon(props: SvgIconProps) {
    return <Insights {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SKILL BLOCK ICON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function SkillBlockIcon(props: SvgIconProps) {
    return <AccountTree {...props} style={{ ...props.style, color: props.color || yellowMain, transform: 'rotate(270deg) scaleX(-1)' }} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SKILL ICON --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function SkillIcon(props: SvgIconProps) {
    return <ViewAgenda {...props} style={{ ...props.style, color: props.color || magentaMain }} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- DESCRIPTOR ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function DescriptorIcon(props: SvgIconProps) {
    return <FormatListBulleted {...props} color={props.color || 'primary'} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ACTIVITY ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ActivityIcon(props: SvgIconProps) {
    return <Category {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- TASK ICON --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function TaskIcon(props: SvgIconProps) {
    return <ViewAgenda {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- ATYPICAL ITEM ICON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function AtypicalItemIcon(props: SvgIconProps) {
    return <FormatListBulleted {...props} color={props.color || 'primary'} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECTED REPOSITORY ITEM ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function SelectedRepositoryItemIcon(props: SvgIconProps) {
    return <CheckBoxOutlined {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- CERTIFIED ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function CertifiedIcon(props: SvgIconProps) {
    return <Grade {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- REFLEXIVITY AXIS ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ReflexivityAxisIcon(props: SvgIconProps) {
    return <PsychologyAlt {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ARTICLE ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ArticleIcon(props: SvgIconProps) {
    return <NewReleases {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ FAVORITE ICON ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function FavoriteIcon(props: SvgIconProps) {
    return <Star {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- FAVORITE EMPTY ICON ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function FavoriteEmptyIcon(props: SvgIconProps) {
    return <GradeOutlined {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- PEDAGOGICAL TOOL ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function PedagogicalToolIcon(props: SvgIconProps) {
    return <Collections {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- PROOF ITEM ICON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function ProofItemIcon(props: SvgIconProps) {
    return <VerifiedUser {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SHEET ICON --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function SheetIcon(props: SvgIconProps) {
    return <Description {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SIGNATURE GROUP ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function SignatureGroupIcon(props: SvgIconProps) {
    return <HistoryEdu {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- SCRIPT THUMBNAIL ICON --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ScriptThumbnailIcon(props: SvgIconProps) {
    return <Style {...props} />;
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SCRIPT MATCH ICON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function ScriptMatchIcon(props: SvgIconProps) {
    return <ReceiptLong {...props} />;
}
