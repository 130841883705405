import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { IconButton, Tooltip, Badge, Fab } from '@mui/material';
import { Info as InfoIcon, Add as AddIcon, HelpCenter as QuestionInfo } from '@mui/icons-material';

// Services
import { ITask, ICustomRepository, ISelfEvaluationResult } from 'services/interfaces';
import { EMastery, ERepositoryItemType, EStepType } from 'services/enums';

// Views
import SuccessIndicators from './success-indicators';
import ObservableElements from './observable-elements';
import { RatingButton, ResponsiveTable, RatingGlobalButton, RatingSelfMasteryButton, getSelfResultIcon, SelfResultInfos } from '../utils';
import { RepositoryItemsFormContext, IRepositoryItemsFormContext } from '../../index';

interface IState {
    selectedTask: ITask | undefined;
    openSelfResultInfos: boolean;
    openObservableElements: boolean;
    openSuccessIndicators: boolean;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- RATING TASKS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RatingTasks({
    customRepository,
    selfEvaluationResults,
} : {
    customRepository: ICustomRepository | undefined,
    selfEvaluationResults: ISelfEvaluationResult[],
}) {

    const { t } = useTranslation();
    
    const {
        setRepositoryItems,
        stepType,
        tasks: repositoryItems,
        enabledObservableElements,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const [state, setState] = useState<IState>({
        selectedTask: undefined,
        openSelfResultInfos: false,
        openObservableElements: false,
        openSuccessIndicators: false,
    });

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <td />
                        {(
                            stepType !== EStepType.SELF_EVALUATION
                            && repositoryItems.length > 1
                        ) ? (
                            <td className="table-td">
                                <RatingGlobalButton
                                    repositoryItemType={ERepositoryItemType.TASK}
                                    repositoryItems={repositoryItems}
                                    setRepositoryItems={setRepositoryItems}
                                />
                            </td>
                        ) : (
                            <td />
                        )}
                        {selfEvaluationResults.length > 0 && (
                            <td className="table-td text-center">
                                <Tooltip title={t('self_evaluation_results')}>
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, openSelfResultInfos: true }))}>
                                        <QuestionInfo fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                {state.openSelfResultInfos && (
                                    <SelfResultInfos
                                        title={t('self_evaluation_results')}
                                        onClose={() => setState(prevState => ({ ...prevState, openSelfResultInfos: false }))}
                                    />
                                )}
                            </td>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {repositoryItems.map((repositoryItem, index) => {

                        let task = customRepository?.activityRepository?.activities
                            ?.find(a => a.tasks.some(t => t.id === repositoryItem.id))?.tasks
                            ?.find(t => t.id === repositoryItem.id);

                        return (
                            <ResponsiveTable
                                key={index}
                                index={index}
                                children1={(
                                    <div className="inline-flex">
                                        <span>
                                            {task?.activity?.position}.{task?.position} {task?.name}
                                        </span>
                                        {(
                                            task?.successIndicators
                                            && task.successIndicators.length > 0
                                        ) && (
                                            <Tooltip title={t('success_indicators')}> 
                                                <IconButton
                                                    onClick={() => setState(prevState => ({ ...prevState, openSuccessIndicators: true, selectedTask: task }))}
                                                    style={{ padding: 6 }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                )}
                                children2={(
                                    (stepType === EStepType.SELF_EVALUATION) ? (
                                        <RatingSelfMasteryButton
                                            itemId={repositoryItem.id}
                                            selfMastery={repositoryItem.selfMastery}
                                            repositoryItemType={ERepositoryItemType.TASK}
                                            repositoryItems={repositoryItems}
                                            setRepositoryItems={setRepositoryItems}
                                        />
                                    ) : (
                                        <RatingButton
                                            itemId={repositoryItem.id}
                                            mastery={repositoryItem.mastery}
                                            repositoryItemType={ERepositoryItemType.TASK}
                                            repositoryItems={repositoryItems}
                                            setRepositoryItems={setRepositoryItems}
                                        />
                                    )
                                )}
                                children3={(
                                    selfEvaluationResults.length > 0
                                    ? getSelfResultIcon(repositoryItem.id, ERepositoryItemType.TASK, selfEvaluationResults)
                                    : undefined
                                )}
                                children4={(
                                    enabledObservableElements
                                    && repositoryItem.mastery === EMastery.NOT_VALIDATED
                                    && (task?.observableElements && task.observableElements.length > 0)
                                ) ? (
                                    <Tooltip title={t('specify_observable_elements_to_work_on')}>
                                        <Badge
                                            badgeContent={repositoryItem.observableElements?.length}
                                            invisible={repositoryItem.observableElements == null || repositoryItem.observableElements.length === 0}
                                            color="secondary"
                                            overlap="circular"
                                        >
                                            <Fab
                                                onClick={() => setState(prevState => ({ ...prevState, openObservableElements: true, selectedTask: task }))}
                                                style={{ boxShadow: 'none' }}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </Badge>
                                    </Tooltip>
                                ) : (
                                    undefined
                                )}
                            />
                        );
                    })}
                </tbody>
            </table>
            {/* -------------------- SUCCESS INDICATORS -------------------- */}
            {(state.openSuccessIndicators && state.selectedTask) && (
                <SuccessIndicators
                    task={state.selectedTask}
                    onClose={() => setState(prevState => ({ ...prevState, openSuccessIndicators: false }))}
                />
            )}
            {/* -------------------- OBSERVABLE ELEMENTS -------------------- */}
            {(enabledObservableElements && state.openObservableElements && state.selectedTask) && (
                <ObservableElements
                    task={state.selectedTask}
                    onClose={() => setState(prevState => ({ ...prevState, openObservableElements: false }))}
                    repositoryItems={repositoryItems}
                    setRepositoryItems={setRepositoryItems}
                />
            )}
        </>
    );
}
