import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { IconButton, Tooltip } from '@mui/material';
import { HelpCenter as QuestionInfo } from '@mui/icons-material';

// Services
import { ICustomRepository, IRepositoryItem, ISelfEvaluationResult } from 'services/interfaces';
import { ERepositoryItemType, EStepType } from 'services/enums';

// Views
import {
    getSelfResultIcon,
    RatingButton,
    RatingGlobalButton,
    RatingSelfMasteryButton,
    ResponsiveTable,
    SelfResultInfos
} from '../utils';
import { IRepositoryItemsFormContext, RepositoryItemsFormContext } from '../../index';
import { getOriginSpan } from '../../../utils';

interface IRepositoryItemWithName extends IRepositoryItem {
    name: string;
    parentName?: string;
    origin?: string;
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- RATING DESCRIPTORS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function RatingDescriptors({
    customRepository,
    selfEvaluationResults,
} : {
    customRepository: ICustomRepository | undefined,
    selfEvaluationResults: ISelfEvaluationResult[],
}) {

    const {
        setRepositoryItems,
        stepType,
        descriptors: repositoryItems,
        linkItems,
    } = useContext(RepositoryItemsFormContext) as IRepositoryItemsFormContext;

    const { t } = useTranslation();
    const [openSelfResultInfos, setOpenSelfResultInfos] = useState(false);

    const repositoryItemsWithName = useMemo(() => {
        let items : IRepositoryItemWithName[] = [];

        if (customRepository?.customBlocks) {

            customRepository.customBlocks.forEach(cb => {

                cb.customSkills.forEach(cs => {

                    cs.descriptors.forEach(d => {
                        let item = repositoryItems.find(i => i.id === d.id);
                        if (item) {
                            items.push({ ...item, name: d.name, origin: cs.skill.name, parentName: t(d.descriptorType.toLowerCase()) });
                        }
                    });

                    cs.linkItems.forEach(li => {
                        let item = linkItems.find(i => i.id === li.id);
                        if (item) {
                            items.push({ ...item, name: li.atypicalItem?.name, origin: cs.skill.name, parentName: li.atypicalItem?.parentItem?.name, itemType: ERepositoryItemType.LINK_ITEM });
                        }
                    })

                    cs.missingDescriptors.forEach(d => {
                        let item = repositoryItems.find(i => i.id === d.id);
                        if (item) {
                            items.push({ ...item, name: d.name, parentName: cs.skill.name });
                        }
                    });
                });

                cb.missingSkills.forEach(ms => {
                    ms.descriptors.forEach(d => {
                        let item = repositoryItems.find(i => i.id === d.id);
                        if (item) {
                            items.push({ ...item, name: d.name, parentName: ms.name });
                        }
                    });
                });
            });
        }

        return items;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repositoryItems, repositoryItems.length, linkItems, linkItems.length]);

    const getNameAndOriginSpan = (repositoryItem: IRepositoryItemWithName) => {
        return (
            <>
                {repositoryItem.parentName && (
                    <>
                        <span className="subtitle">
                            {repositoryItem.parentName}
                        </span>
                        <br/>
                    </>
                )}
                {repositoryItem.name}
                {repositoryItem.origin && getOriginSpan(repositoryItem.origin)}
            </>
        )
    }

    return (
        <table>
            <thead>
                <tr>
                    <td />
                    {(
                        stepType !== EStepType.SELF_EVALUATION
                        && repositoryItemsWithName.length > 1
                    ) ? (
                        <td className="table-td">
                            <RatingGlobalButton
                                repositoryItemType={ERepositoryItemType.DESCRIPTOR}
                                repositoryItems={repositoryItems}
                                linkItems={linkItems}
                                setRepositoryItems={setRepositoryItems}
                            />
                        </td>
                    ) : (
                        <td />
                    )}
                    {selfEvaluationResults.length > 0 && (
                        <td className="table-td text-center">
                            <Tooltip title={t('self_evaluation_results')}>
                                <IconButton onClick={() => setOpenSelfResultInfos(true)}>
                                    <QuestionInfo fontSize="large" />
                                </IconButton>
                            </Tooltip>
                            {openSelfResultInfos && (
                                <SelfResultInfos
                                    title={t('self_evaluation_results')}
                                    onClose={() => setOpenSelfResultInfos(false)}
                                />
                            )}
                        </td>
                    )}
                </tr>
            </thead>
            <tbody>
                {repositoryItemsWithName.map((repositoryItem, index) => (
                    <ResponsiveTable
                        key={index}
                        index={index}
                        children1={getNameAndOriginSpan(repositoryItem)}
                        children2={(
                            (stepType === EStepType.SELF_EVALUATION) ? (
                                <RatingSelfMasteryButton
                                    itemId={repositoryItem.id}
                                    selfMastery={repositoryItem.selfMastery}
                                    repositoryItemType={repositoryItem.itemType || ERepositoryItemType.DESCRIPTOR}
                                    repositoryItems={repositoryItem.itemType === ERepositoryItemType.LINK_ITEM ? linkItems : repositoryItems}
                                    setRepositoryItems={setRepositoryItems}
                                />
                            ) : (
                                <RatingButton
                                    itemId={repositoryItem.id}
                                    mastery={repositoryItem.mastery}
                                    repositoryItemType={repositoryItem.itemType || ERepositoryItemType.DESCRIPTOR}
                                    repositoryItems={repositoryItem.itemType === ERepositoryItemType.LINK_ITEM ? linkItems : repositoryItems}
                                    setRepositoryItems={setRepositoryItems}
                                />
                            )
                        )}
                        children3={(
                            selfEvaluationResults.length > 0
                            ? getSelfResultIcon(repositoryItem.id, (repositoryItem.itemType ?? ERepositoryItemType.DESCRIPTOR), selfEvaluationResults)
                            : undefined
                        )}
                    />   
                ))}
            </tbody>
        </table>
    );
}
